/**
 * @fileoverview Schools data file containing information about family sports rivalries
 * @copyright 2024 Bob Keathley. All Rights Reserved.
 * This file contains proprietary and confidential information.
 * Unauthorized copying, use, distribution, or modification is strictly prohibited
 */

export const schools = [
  {
    name: 'University of Texas',
    logo: '/images/schools/ut-logo.png',
    url: 'https://www.utexas.edu',
    altText: 'University of Texas at Austin logo'
  },
  {
    name: 'Texas A&M University',
    logo: '/images/schools/tamu-logo.png',
    url: 'https://www.tamu.edu',
    altText: 'Texas A&M University logo'
  },
  {
    name: 'University of Alabama',
    logo: '/images/schools/alabama-logo.png',
    url: 'https://www.ua.edu',
    altText: 'University of Alabama logo'
  },
  {
    name: 'Louisiana Tech University',
    logo: '/images/schools/latech-logo.png',
    url: 'https://www.latech.edu',
    altText: 'Louisiana Tech University logo'
  },
  {
    name: 'Southern Methodist University',
    logo: '/images/schools/smu-logo.png',
    url: 'https://www.smu.edu',
    altText: 'SMU logo'
  },
  {
    name: 'University of Southern California',
    logo: '/images/schools/usc-logo.png',
    url: 'https://www.usc.edu',
    altText: 'USC logo'
  }
]; 