/**
 * @fileoverview Schools Section Component for displaying family sports rivalries
 * @copyright 2024 Bob Keathley. All Rights Reserved.
 * This file contains proprietary and confidential information.
 * Unauthorized copying, use, distribution, or modification is strictly prohibited
 */

import React from 'react';
import { motion } from 'framer-motion';
import { schools } from '../data/schools';
import styles from './SchoolsSection.module.css';

/**
 * SchoolsSection Component
 * Displays a grid of school logos representing family sports rivalries
 * @returns {React.Component} The SchoolsSection component
 */
const SchoolsSection = () => {
  return (
    <section className={styles.schoolsSection}>
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
        className={styles.title}
      >
        Family Sports Rivalries
      </motion.h2>
      <div className={styles.schoolsGrid}>
        {schools.map((school, index) => (
          <motion.a
            key={school.name}
            href={school.url}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.schoolCard}
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <div className={styles.logoContainer}>
              <img
                src={school.logo}
                alt={school.altText}
                className={styles.logo}
              />
            </div>
            <p className={styles.schoolName}>{school.name}</p>
          </motion.a>
        ))}
      </div>
    </section>
  );
};

export default SchoolsSection; 