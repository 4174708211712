/**
 * @fileoverview Main App Component
 * @copyright 2024 Bob Keathley. All Rights Reserved.
 * This file contains proprietary and confidential information.
 * Unauthorized copying, use, distribution, or modification is strictly prohibited
 */

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import HomePage from './pages/HomePage';
import WorkPage from './pages/WorkPage';
import LeadershipPage from './pages/LeadershipPage';
import InnovationPage from './pages/InnovationPage';
import PerformancePage from './pages/PerformancePage';
import ExpertisePage from './pages/ExpertisePage';
import PersonalPage from './pages/PersonalPage';
import ContactPage from './pages/ContactPage';
import AuthPage from './pages/AuthPage';
import TivoliPage from './pages/TivoliPage';
import LandmarkPage from './pages/LandmarkPage';
import ChaoticPage from './pages/ChaoticPage';
import OFMPPage from './pages/OFMPPage';
import './App.css';

const App = () => {
  return (
    <Router>
      <div className="app">
        <Navigation />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/work" element={<WorkPage />} />
            <Route path="/leadership" element={<LeadershipPage />} />
            <Route path="/innovation" element={<InnovationPage />} />
            <Route path="/performance" element={<PerformancePage />} />
            <Route path="/expertise" element={<ExpertisePage />} />
            <Route path="/personal" element={<PersonalPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/auth/*" element={<AuthPage />} />
            <Route path="/tivoli" element={<TivoliPage />} />
            <Route path="/landmark" element={<LandmarkPage />} />
            <Route path="/chaotic" element={<ChaoticPage />} />
            <Route path="/ofmp" element={<OFMPPage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
};

export default App; 