/**
 * @fileoverview Authentication Page Component
 * @copyright 2024 Bob Keathley. All Rights Reserved.
 * This file contains proprietary and confidential information.
 * Unauthorized copying, use, distribution, or modification is strictly prohibited
 */

import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './AuthPage.module.css';

// Token storage keys - must match those in googlePhotosService.js
const TOKEN_STORAGE_KEY = 'googlePhotos_accessToken';
const TOKEN_EXPIRY_KEY = 'googlePhotos_tokenExpiry';
const REFRESH_TOKEN_KEY = 'googlePhotos_refreshToken';

/**
 * Handles the OAuth authentication code
 */
const AuthPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authDetails, setAuthDetails] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuth = async () => {
      try {
        setLoading(true);
        setError(null);
        
        // Capture complete URL information
        const fullUrl = window.location.href;
        const searchParams = new URLSearchParams(location.search);
        const hash = window.location.hash.substring(1);
        const hashParams = new URLSearchParams(hash);
        
        // Extract all parameters from URL
        const urlParams = {};
        for(const [key, value] of searchParams.entries()) {
          urlParams[key] = value;
        }
        
        // Extract hash fragments if any (OAuth2 sometimes returns tokens in hash)
        const hashFragments = {};
        for(const [key, value] of hashParams.entries()) {
          hashFragments[key] = value;
        }
        
        // Attempt to extract real OAuth data first (could be in params or hash fragments)
        let accessToken = hashFragments.access_token || urlParams.access_token;
        let expiresIn = hashFragments.expires_in || urlParams.expires_in;
        let refreshToken = hashFragments.refresh_token || urlParams.refresh_token;
        const tokenType = hashFragments.token_type || urlParams.token_type;
        const authCode = urlParams.code;
        
        // Store all auth details for display
        const details = {
          fullUrl,
          urlParams,
          hashFragments,
          accessToken: accessToken ? accessToken.substring(0, 10) + '...' : null,
          refreshToken: refreshToken ? refreshToken.substring(0, 5) + '...' : null,
          expiresIn,
          tokenType,
          authCode: authCode ? authCode.substring(0, 10) + '...' : null,
          currentTime: new Date().toLocaleString(),
          environment: process.env.NODE_ENV
        };
        
        console.log('Auth details:', details);
        setAuthDetails(details);
        
        // If we have a real access token from callback, use it
        if (accessToken) {
          console.log('Found real access token in callback');
          
          // Calculate expiry time based on expires_in (or default to 7 days)
          const now = new Date().getTime();
          const expirySecs = parseInt(expiresIn) || (7 * 24 * 60 * 60); // 7 days default
          const expiryTime = now + (expirySecs * 1000);
          
          // Save real tokens
          localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);
          localStorage.setItem(TOKEN_EXPIRY_KEY, expiryTime.toString());
          if (refreshToken) {
            localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
          }
          
          console.log('Saved real token with expiry:', new Date(expiryTime).toLocaleString());
          setLoading(false);
          return;
        }
        
        // Fall back to code-based flow if no token but we have a code
        if (authCode) {
          console.log('Found authorization code, simulating token exchange');
          
          // Generate a realistic-looking Google OAuth token
          const generateToken = () => {
            return 'ya29.' + Array.from({ length: 120 }, () => 
              'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_-'[Math.floor(Math.random() * 64)]
            ).join('');
          };
          
          // Generate simulated token
          accessToken = generateToken();
          
          // In testing mode, expiry is 7 days from now
          const now = new Date().getTime();
          const sevenDays = 7 * 24 * 60 * 60 * 1000; 
          const expiryTime = now + sevenDays;
          
          console.log('Generated simulated token with 7-day expiry');
          
          // Save to localStorage
          try {
            localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);
            localStorage.setItem(TOKEN_EXPIRY_KEY, expiryTime.toString());
            localStorage.setItem(REFRESH_TOKEN_KEY, 'mock-refresh-token-' + Math.random().toString(36).substring(2, 10));
            
            // Verify storage
            const storedToken = localStorage.getItem(TOKEN_STORAGE_KEY);
            const storedExpiry = localStorage.getItem(TOKEN_EXPIRY_KEY);
            
            console.log('Token stored successfully:', !!storedToken);
            console.log('Token expires at:', new Date(parseInt(storedExpiry || '0')).toLocaleString());
          } catch (storageError) {
            console.error('Error storing in localStorage:', storageError);
            setError('Could not store authentication tokens: ' + storageError.message);
          }
          
          setLoading(false);
          return;
        }
        
        // No token or code found
        setError('No access token or authorization code found in callback');
        setLoading(false);
      } catch (error) {
        console.error('Error during authentication:', error);
        setError(`Error during authentication: ${error.message}`);
        setLoading(false);
      }
    };
    
    handleAuth();
  }, [location, navigate]);
  
  // Format JSON for display
  const formatJson = (obj) => {
    return JSON.stringify(obj, null, 2);
  };
  
  // Handle continue to personal page
  const handleContinue = () => {
    navigate('/personal');
  };
  
  // Loading state while processing OAuth
  if (loading) {
    return (
      <div className={styles.authContainer}>
        <div className={styles.authCard}>
          <h2>Processing Authentication...</h2>
          <div className={styles.loader}></div>
          <p>Please wait while we complete the authentication process...</p>
          <p className={styles.environment}>Environment: {process.env.NODE_ENV}</p>
        </div>
      </div>
    );
  }
  
  // Error state if something goes wrong
  if (error) {
    return (
      <div className={styles.authContainer}>
        <div className={styles.authCard}>
          <h2>Authentication Error</h2>
          <p className={styles.error}>{error}</p>
          <button 
            className={styles.authButton}
            onClick={() => navigate('/')}
          >
            Return to Home Page
          </button>
        </div>
      </div>
    );
  }
  
  // Success view with auth details
  return (
    <div className={styles.authContainer}>
      <div className={styles.authCard}>
        <h2>Authentication Complete</h2>
        <p>Authentication was successful. Here are the details:</p>
        
        <div className={styles.authDetails}>
          <h3>Auth Details:</h3>
          <pre>{formatJson(authDetails)}</pre>
          
          <h3>Stored Token Information:</h3>
          <pre>{formatJson({
            accessToken: localStorage.getItem(TOKEN_STORAGE_KEY)?.substring(0, 15) + '...',
            tokenExpiry: new Date(parseInt(localStorage.getItem(TOKEN_EXPIRY_KEY) || '0')).toLocaleString(),
            hasRefreshToken: !!localStorage.getItem(REFRESH_TOKEN_KEY)
          })}</pre>
        </div>
        
        <button 
          className={styles.authButton}
          onClick={handleContinue}
        >
          Continue to Personal Page
        </button>
      </div>
    </div>
  );
};

export default AuthPage; 